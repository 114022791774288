export default [
  {
    path: '/anniversary/10th',
    name: 'Anniversary',
    component: () => import('@/views/anniversary/Index.vue'),
    children: [
      {
        path: '',
        name: 'AnniversaryHome',
        component: () => import('@/views/anniversary/Home.vue')
      },
      {
        path: 'friend_invitation',
        name: 'FriendInvitation',
        component: () => import('@/views/anniversary/invitation/Index.vue')
      },
      {
        path: 'friend_invitation/app',
        name: 'FriendInvitationApp',
        component: () => import('@/views/anniversary/invitation/Index.vue')
      },
      {
        path: 'diagnosis',
        name: 'Diagnosis',
        component: () => import('@/views/anniversary/diagnosis/Index.vue')
      },
      {
        path: 'diagnosis/result',
        name: 'DiagnosisResult',
        component: () => import('@/views/anniversary/diagnosis/result/Index.vue'),
        meta: { authRequired: true }
      },
      {
        path: 'diagnosis/result/:code',
        name: 'DiagnosisResultCode',
        component: () => import('@/views/anniversary/diagnosis/result/Index.vue')
      },
      {
        path: 'free_pass',
        name: 'FreePass',
        component: () => import('@/views/anniversary/free_pass/Index.vue')
      },
      {
        path: 'diagnosis/error',
        name: 'DiagnosisError',
        component: () => import('@/views/anniversary/diagnosis/error/Index.vue')
      },
      {
        path: 'special_mission',
        name: 'SpecialMission',
        component: () => import('@/views/anniversary/SpecialMission.vue')
      },
      {
        path: 'error',
        name: 'AnniversaryError',
        component: () => import('@/views/anniversary/Error.vue'),
      },
      {
        path: 'pastel',
        name: 'Pastel',
        component: () => import('@/views/anniversary/pastel/Index.vue')
      },
      {
        path: 'sns_campaign',
        name: 'sns_campaign',
        component: () => import('@/views/anniversary/sns_campaign/Index.vue')
      }
    ]
  }
];