var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{on:{"click":function($event){$event.stopPropagation();return _vm.onClick($event)}}},[_c('a',{attrs:{"href":typeof _vm.element.urlHttp === 'undefined' ? '' : _vm.getForwardPath(_vm.element.urlHttp).path},on:{"click":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"thumb_comic",attrs:{"id":_vm.setIndex ? ("recomm_" + _vm.index) : ''}},[_c('img',{staticClass:"img_thumb",attrs:{"src":_vm.imagePathCheck(_vm.element.imageUrl),"alt":_vm.element.title},on:{"error":_vm.imageLoadErrorCheck}}),_c('strong',{staticClass:"blind"},[_vm._v(_vm._s(_vm.element.title))]),(_vm.element.original)?_c('icon-thumbnail',{attrs:{"clsName":_vm.originalIconClsName()}}):_vm._e()],1),_c('span',{staticClass:"thumb_info"},[(_vm.element.badge && _vm.element.badge.type === 'event')?[_c('span',{staticClass:"txt_viewer"},[_c('div',[_c('strong',{staticClass:"tit_comic",class:{
                emph_new: _vm.element.status === 'started',
                emph_up: _vm.element.status === 'updated',
              }}),_c('span',{staticClass:"txt_viewer_title",class:{
                emph_new: _vm.element.status === 'started',
                emph_up: _vm.element.status === 'updated'
            }},[_vm._v(_vm._s(_vm.element.title))])]),_c('div',[_c('badge',{attrs:{"badge":_vm.element.badge,"status":_vm.element.status}})],1)])]:(_vm.elementUiType === 'content_basic' || _vm.elementUiType === 'content_big')?[_c('span',{staticClass:"txt_viewer"},[_c('div',[_c('strong',{staticClass:"tit_comic",class:{
                emph_new: _vm.element.status === 'started',
                emph_up: _vm.element.status === 'updated',
              }}),_c('span',{staticClass:"txt_viewer_title",class:{
                emph_new: _vm.element.status === 'started',
                emph_up: _vm.element.status === 'updated'
            }},[_vm._v(_vm._s(_vm.element.title))])]),_c('div',{staticClass:"numviews"},[_vm._v(_vm._s(_vm.totalViews))])])]:(_vm.elementUiType === 'banner_list_content_big' || _vm.elementUiType === 'banner_list_content_big_group' )?[_c('span',{staticClass:"txt_viewer txt_viewer_title banner_list_content_big"},[_vm._v(" "+_vm._s((_vm.element.title)? _vm.element.title: _vm.element.additionalText)+" ")])]:[_c('span',{staticClass:"txt_viewer"},[_c('div',[_c('strong',{staticClass:"txt_viewer_title",class:{
                emph_new: _vm.element.status === 'started',
                emph_up: _vm.element.status === 'updated',
              }}),_c('span',{staticClass:"txt_viewer_title",class:{
                emph_new: _vm.element.status === 'started',
                emph_up: _vm.element.status === 'updated'
            }},[_vm._v(_vm._s(_vm.element.title))])]),_c('div',{staticClass:"numviews"},[_vm._v(_vm._s(_vm.totalViews))])])]],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }